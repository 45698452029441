
.site-header{
	padding-bottom: 0;
	padding-top: 0;
}
.navbar-header{
	height: 100px;
}
.navbar-brand img{
	top: 100%;
}
.navbar-default .navbar-nav > li > a{
	color: #fff;
	font-weight: bold;
}
.navbar-brand img{
	width: 93px;
	margin-left: 51px;
	@media (max-width: 768px) {
		top:50%;
	}
}

@media (max-width: 768px) {
	.navbar-default .navbar-nav > li > a{
		color: #000;
	}
}