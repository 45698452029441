
body{
	background-color: #aaa;
	font-family: 'Poppins', sans-serif;
	li{
		display: inline-block;
		list-style: none;
	}
	a{
		color: #fff;
		&:hover{
			text-decoration:none;
			color:inherit;
		}
	}
}

.navbar-default .navbar-toggle .icon-bar{
	background-color: #fff;
}

@media only screen and (max-width: 767px){
	.navbar-brand .logo-2{
		margin-left: 0;
		top: -11px;
	}
}

.btn-default{
	background-color: $prymary_color;
	padding: 10px 15px;
	width: 317.9px;
	color: #fff;
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 4px;
	text-transform: uppercase;


	@media (max-width: 765px) {
		
	}
}