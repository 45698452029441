


.main-slider{
	width:100%;
	height: 100vh;
	.bg-slider{
		width: 100%;
		height: 100vh;
		background-size: cover;
		position: relative;
		z-index: -2;
		.wrap{
			z-index: 9;
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			opacity: 1;

			.content{
				margin: auto;
				text-align: center;
				color:#fff;
				h1{
					color:#fff;
					font-size: 20px;
					font-weight: bold;
					text-transform: uppercase;
					line-height: 1.75;
				}
				p{
					text-transform: uppercase;
					font-size: 30px;
					margin-bottom: 20px;
					line-height: 1.17;
					width: 828px;
					@media (max-width: 828px) {
						width:100%;
					}
					@media (max-width: 425px) {
						font-size: 27px;
					}
				}
			}
		}
	}
}

.ofer{
	background-color: #fff;
	padding: 100px 0;
	h2{
		font-weight: bold;
		letter-spacing: normal;
		text-align: center;
		color: $prymary_color;
		font-size: 18px;
		margin-bottom: 11px;
	}
	.subtitle{
		font-size: 30px;
		line-height: 1.17;
		text-align: center;
		color: $secondary_color;
		margin-bottom: 54px;
	}
	.box{
		width: 272.1px;
		height: 272.8px;
		margin: auto;
		border: solid 1px $secondary_color;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.5s;
		@media (max-width: 990px) {
			margin-bottom: 20px;
		}
		&:hover{
			border: solid 1px $prymary_color;
			svg{
				fill: $prymary_color;
			}
			.box-title{
				color: $prymary_color;
			}
		}
		.box-title{
			transition: 0.5s;
			line-height: 2.19;
			color: $secondary_color;
			font-size: 16px;
			text-align: center;

		}
		svg{
			width: 58px;
			height: 49px;
			margin: auto;
			fill : #4a4a4a;
			transition: 0.5s;

			&.fa-wedding{
				width: 60px;
				height: 60px;
			}
			&.fa-party{
				width: 62px;
				height: 65px;
			}
			&.fa-business{
				width: 58px;
				height: 49px;
			}
		}
	}

}

.why-esferic{
	padding: 100px 0;
	background-color: #f4f3f4;
	h2{
		font-weight: bold;
		letter-spacing: normal;
		text-align: center;
		color: $prymary_color;
		font-size: 18px;
		margin-bottom: 11px;
	}
	.subtitle{
		font-size: 30px;
		line-height: 1.17;
		text-align: center;
		color: $secondary_color;
		margin-bottom: 54px;
	}
	.quality{
		background-color: #ffffff;
		padding: 60px 5px;
		text-align: center;
		min-height: 414px;
		@media (max-width: 991px) {
			margin: 20px 0;
			padding: 60px 15px;
		}
		@media (max-width: 768px) {
			min-height: 0;
		}
		.title{
			font-size: 16px;
			line-height: 1.38;
			color: #4a4a4a;
			font-weight: bold;
			margin-bottom: 13px;
		}
		.text{
			p{
				color: #4a4a4a;
				font-size: 13px;
				line-height: 1.46;
				margin: 4px;
			}
			hr{
				margin: 7px;
			}
		}
	}
}

.clients{
	background-color: #f4f3f4;
	.container-fluid{
		padding-left: 0;
		padding-right: 0;
	}
	.no-padding{
		padding: 0px;
	}
	.title{
		font-weight: bold;
		letter-spacing: normal;
		text-align: center;
		color: $secondary_color;
		font-size: 18px;
		margin-bottom: 11px;
	}

	.wrapimg{
		width: 100%;
		height: 189.7px;
		background-color: #888;
	}
	.wrapinfo{
		display: flex;
		width: 100%;
		height: 189.7px;
		justify-content: center;
		align-items: center;
		background-color: #131d29;
		padding: 5px;
		.post{
			color: #fff;
			text-align: center;
			line-height: 1.54;
			font-size: 13px;
		}
		.name{
			font-size: 13px;
			line-height: 1.62;
			color: #fff;
			text-transform: uppercase;
			font-weight: bold;
			text-align: center;
		}
	}
}

.contact{
	padding: 100px 0;
	background-color: #fff;
	h2{
		font-weight: bold;
		letter-spacing: normal;
		text-align: center;
		color: $prymary_color;
		font-size: 18px;
		margin-bottom: 11px;
	}
	.subtitle{
		font-size: 30px;
		line-height: 1.17;
		text-align: center;
		color: $secondary_color;
		margin-bottom: 54px;
	}
	form{
		.group{
			margin-bottom: 10px;
		}
		span{
			color: #9b9b9b;
			font-size: 15px;
			line-height: 2.67;
			text-align: right;
			@media (max-width: 765px) {
				text-align: center;
			}
		}
		input, select, option{
			border: solid 1px #979797;
			background-color: #fff;
			height: 40px;
			width: 326px;
			border-radius: 0;
			padding: 0 15px;
			font-size: 15px;
			line-height: 2.67;
			color: #9b9b9b;
			@media (max-width: 765px) {
				display: block;
				margin: auto;
			}
			@media (max-width: 450px) {
				width: 250px;
			}
		}
		select{
			font-size: 15px;
			line-height: 2.67;
			color: #9b9b9b;
		}
		[type="submit"]{
			border: none;
			margin-top: 24px;
			color: #fff;
			width: 158px;
			background-color: #ff7d53;
		}
	}
}

.partners{
	padding: 100px 0;
	background-color: #e0e0e0;
	.title{
		text-align: center;
		font-size: 18px;
		color: #4a4a4a;
		line-height: 1.94;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.wrapicon{
		background-color: #fff;
		display: flex;
		width: 100%;
		height: 147.4px;
		margin: 15px 0;
		font-weight: bold;
		@media (max-width: 765px) {
			margin: 5px 0;
		}
	}
}








